.inputWrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("/public/paper\ background.avif");
  border-radius: 25px;
  padding: 15px;
}

.readyText {
  font-family: fantasy;
  font-size: 30px;
}

.pdfWrapper{
  width: 100%;
  height: 200px;
  background-image: url("/public/paper\ background.avif");
  border-radius: 25px;
  padding: 25px;
  margin-top: 10px;
}