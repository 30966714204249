html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

#page {
  background-image: url("/public/main\ page\ background.jpg");
  overflow: auto;
  display: flex;
  width: 100%;
  height: 100%;
}

.wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  margin: auto;
  /* background-color: thistle; */
  background-image: linear-gradient(180deg, black, transparent);
  grid-template-areas:
    "logo two two"
    "logo two two"
    "three three four"
    "three three four"
    "five five five";

  grid-template-rows: 17.5% 17.5% 20% 20% 25%;

  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.logo {
  grid-area: logo;
  background-image: url("/public/67ZerosLogo.PNG");
  background-size: 50%;
  background-repeat: no-repeat;
  margin-left: 100px;
  margin-top: 20px;
}

.two {
  grid-area: two;
  margin: 5px;
  margin-left: 10px;
  margin-right: 50px;
  /* background-color: #ffa08c; */
}

.three {
  grid-area: three;
  margin: 5px;
  margin-left: 10px;
  margin-right: 10px;
  /* background-color: aqua; */
}

.four {
  grid-area: four;
  margin: 5px;
  margin-left: 10px;
  margin-right: 50px;
}

.five {
  grid-area: five;
  margin: 5px;
}