.explanation {
  font-family: fantasy;
  display: inline;
  font-size: 30px;
}

.aboutDiv {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("/public/paper\ background.avif");
  border-radius: 25px;
  padding: 20px;
  padding-top: 30px;
}

.arrow {
  float: right;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: -30px;
  height: 120px;
  width: 140px;
}

.arrowWrapper {
  display: inline;
  margin-right: 5px;
  margin-left: 5px;
  height: 250px;
  width: 250px;
}

.arrowText {
  font-family: fantasy;
  font-size: 30px;
  display: inline;
}
