.info {
  height: 90%;
  width: 85%;
  margin-top: 20px;
  padding: 5px;
  overflow: auto;
  background-image: url("/public/paper\ background.avif");
  background-size: 100%;
  border-radius: 25px;
}

.about {
  text-align: center;
  font-size: 40px;
  margin-top: 5px;
  font-family: fantasy;
}

.description {
  margin: 15px;
  margin-top: 5px;
  font-family: fantasy;
  font-size: 25px;
  display: inline;
}

.gif {
  display: inline;
  float: right;
  margin-right: 400px;
  margin-top: 5px;
  height: 75px;
  width: 70px;
  background-image: url("/public/einstein_gif.gif");
  background-size: 100%;
  background-repeat: no-repeat;
}
