html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

#Hpage {
  background-image: url("/public/main\ page\ background.jpg");
  /* overflow: auto; */
  display: flex;
  width: 100%;
  height: 165%;
}

.Hwrapper {
  display: grid;
  width: 100%;
  height: 100%;
  margin: auto;
  /* background-image: linear-gradient(180deg, black, transparent); */
  grid-template-areas:
    "Hone Hone Hone"
    "Htwo Htwo Htwo"
    "Hthree hthree hthree";

  grid-template-rows: 800px 950px 400px;

  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.Hone {
  grid-area: Hone;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 15px;
  overflow: auto;
  height: 91%;
  width: 85%;
  padding: 5px;
  background-image: url("/public/paper\ background.avif");
  background-size: 100%;
  border-radius: 25px;
  /* background-color: #ffa08c; */
}

.Htwo {
  grid-area: Htwo;
  overflow: auto;
  margin: auto;
  margin-top: -50px;
  height: 40%;
  width: 85%;
  padding: 5px;
  background-image: url("/public/paper\ background.avif");
  background-size: 100%;
  border-radius: 25px;
  /* background-color: aqua; */
}

.Hthree {
  grid-area: Htwo;
  overflow: auto;
  margin: auto;
  margin-top: 340px;
  height: 40%;
  width: 85%;
  padding: 5px;
  background-image: url("/public/paper\ background.avif");
  background-size: 100%;
  border-radius: 25px;
  /* background-color: aqua; */
}

.infoText{
  margin: 15px;
  margin-top: 5px;
  font-family: fantasy;
  font-size: 23px;
  display: inline;
}

.graph1{
  margin: 10px;
  margin-right: 35px;
  height: 345px;
  /* display: inline; */
  float: left;
  width: 600px;
  background-image: url("/public/numbers-graph.PNG");
  background-size: 100%;
  border-radius: 10px;
  background-repeat: no-repeat;
}

.graph2{
  margin: 10px;
  height: 345px;
  /* display: inline; */
  float: left;
  width: 600px;
  background-image: url("/public/probability-graph.PNG");
  background-size: 100%;
  border-radius: 10px;
  background-repeat: no-repeat;
}