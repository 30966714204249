.iframeWrapper {
  width: 100%;
  height: 85%;
  border-radius: 25px;
}

.videoHeader {
  font-family: fantasy;
  font-size: 20px;
  color: whitesmoke;
}
